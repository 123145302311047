import { Tooltip } from '@mui/material'
import Anodising from '_icons/workingSteps/anodising-sm.svg?react'
import Assembling from '_icons/workingSteps/assembling-sm.svg?react'
import HemmingIcon from '_icons/workingSteps/bend-closed_sm.svg?react'
import BlastingIcon from '_icons/workingSteps/blasting-sm.svg?react'
import BoltPressing from '_icons/workingSteps/bolt_pressing-sm.svg?react'
import CertifyIcon from '_icons/workingSteps/cf_certify_certify-sm.svg?react'
import DrawingIcon from '_icons/workingSteps/cf_draw_draw-sm.svg?react'
import TransportIcon from '_icons/workingSteps/cf_transport_transport-sm.svg?react'
import CleaningIcon from '_icons/workingSteps/cleaning_sm.svg?react'
import CounterSinkingIcon from '_icons/workingSteps/countersinking-sm.svg?react'
import Deburring from '_icons/workingSteps/deburring-sm.svg?react'
import DieSinkingIcon from '_icons/workingSteps/die_sinking_sm.svg?react'
import DipCoatingIcon from '_icons/workingSteps/dip_coating_sm.svg?react'
import Drilling from '_icons/workingSteps/drilling-sm.svg?react'
import EdgeMilling from '_icons/workingSteps/edge_milling-sm.svg?react'
import EdgeTrimming from '_icons/workingSteps/edge_trimming-sm.svg?react'
import EngravingIcon from '_icons/workingSteps/engraving-sm.svg?react'
import FlowDrillingIcon from '_icons/workingSteps/flow_drilling_sm.svg?react'
import FoilingIcon from '_icons/workingSteps/foiling_sm.svg?react'
import FoldingIcon from '_icons/workingSteps/folding-sm.svg?react'
import HoleProtectioningIcon from '_icons/workingSteps/hole_protectioning_sm.svg?react'
import LevelTensioning from '_icons/workingSteps/level_tensioning-sm.svg?react'
import MillingIcon from '_icons/workingSteps/milling-sm.svg?react'
import NitridingIcon from '_icons/workingSteps/nitriding_sm.svg?react'
import NutPressing from '_icons/workingSteps/nut_pressing-sm.svg?react'
import OperatingIcon from '_icons/workingSteps/operating_sm.svg?react'
import OrbitalSanding from '_icons/workingSteps/orbital sanding_sm.svg?react'
import OrderPickingIcon from '_icons/workingSteps/order_picking-sm.svg?react'
import PackagingIcon from '_icons/workingSteps/packaging-sm.svg?react'
import PicklingPassivating from '_icons/workingSteps/pickling_passivating-sm.svg?react'
import PlateCuttingIcon from '_icons/workingSteps/plate_cutting-sm.svg?react'
import PlateShearing from '_icons/workingSteps/plate_shearing-sm.svg?react'
import Polishing from '_icons/workingSteps/polishing-sm.svg?react'
import PowderCoating from '_icons/workingSteps/powder_coating-sm.svg?react'
import ProgrammingIcon from '_icons/workingSteps/programming-sm.svg?react'
import Punching from '_icons/workingSteps/punching-sm.svg?react'
import PurchasingIcon from '_icons/workingSteps/purchasing-sm.svg?react'
import QualityControllingIcon from '_icons/workingSteps/quality_controlling_sm.svg?react'
import ResearchingIcon from '_icons/workingSteps/researching_sm.svg?react'
import RobotStudWeldingIcon from '_icons/workingSteps/robot_stud_welding_sm.svg?react'
import RobotWeldingIcon from '_icons/workingSteps/robot_welding_sm.svg?react'
import Rolling from '_icons/workingSteps/rolling-sm.svg?react'
import SawingIcon from '_icons/workingSteps/sawing-sm.svg?react'
import StudWelding from '_icons/workingSteps/stud_welding-sm.svg?react'
import ThreadTapping from '_icons/workingSteps/thread_tapping-sm.svg?react'
import TubeBending from '_icons/workingSteps/tube_bending-sm.svg?react'
import TubeOrProfileCuttingIcon from '_icons/workingSteps/tube_or_profile_cutting-sm.svg?react'
import TuringIcon from '_icons/workingSteps/turning-sm.svg?react'
import UndefinedIcon from '_icons/workingSteps/undefined-sm.svg?react'
import VibratoryDeburringIcon from '_icons/workingSteps/vibratory_deburring_sm.svg?react'
import WeldGrindingIcon from '_icons/workingSteps/weld-grinding-sm.svg?react'
import Welding from '_icons/workingSteps/welding-sm.svg?react'
import WireErosionIcon from '_icons/workingSteps/wire_erosion_sm.svg?react'
import {
  default as NicklePlating,
  default as ZincPlating,
} from '_icons/workingSteps/zinc_plating-sm.svg?react'
import MicroJointRemoval from '_icons/workingSteps/micro_joint_removal_sm.svg?react'
import Riveting from '_icons/workingSteps/riveting_sm.svg?react'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import React from 'react'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'

type WorkingStepIconSvgProps = {
  workingStepType: WorkingStepType
  attributes?: React.SVGProps<SVGSVGElement>
  disableTitle?: boolean
  title?: string
  className?: string
  disableTooltip?: boolean
  svgProps?: any
}

const WorkingStepIconsMap: Record<
  WorkingStepType,
  React.FC<React.SVGProps<SVGSVGElement>>
> = {
  [WorkingStepType.SheetCutting]: PlateCuttingIcon,
  [WorkingStepType.Sawing]: SawingIcon,
  [WorkingStepType.SheetBending]: FoldingIcon,
  [WorkingStepType.CounterSinking]: CounterSinkingIcon,
  [WorkingStepType.Milling]: MillingIcon,
  [WorkingStepType.TubeOrProfileCutting]: TubeOrProfileCuttingIcon,
  [WorkingStepType.Turning]: TuringIcon,
  [WorkingStepType.Purchasing]: PurchasingIcon,
  [WorkingStepType.Engraving]: EngravingIcon,
  [WorkingStepType.ThreadTapping]: ThreadTapping,
  [WorkingStepType.Anodising]: Anodising,
  [WorkingStepType.Assembling]: Assembling,
  [WorkingStepType.BoltPressing]: BoltPressing,
  [WorkingStepType.Deburring]: Deburring,
  [WorkingStepType.Drilling]: Drilling,
  [WorkingStepType.EdgeMilling]: EdgeMilling,
  [WorkingStepType.EdgeTrimming]: EdgeTrimming,
  [WorkingStepType.LevelTensioning]: LevelTensioning,
  [WorkingStepType.NutPressing]: NutPressing,
  [WorkingStepType.PicklingPassivating]: PicklingPassivating,
  [WorkingStepType.SheetShearing]: PlateShearing,
  [WorkingStepType.Polishing]: Polishing,
  [WorkingStepType.ElectrolyticPolishing]: Polishing,
  [WorkingStepType.PowderCoating]: PowderCoating,
  [WorkingStepType.Punching]: Punching,
  [WorkingStepType.Rolling]: Rolling,
  [WorkingStepType.StudWelding]: StudWelding,
  [WorkingStepType.TubeProfileOrBarBending]: TubeBending,
  [WorkingStepType.Welding]: Welding,
  [WorkingStepType.ZincPlating]: ZincPlating,
  [WorkingStepType.ZincPlatingBarrel]: ZincPlating,
  [WorkingStepType.ZincPlatingRack]: ZincPlating,
  [WorkingStepType.Designing]: DrawingIcon,
  [WorkingStepType.Certifying]: CertifyIcon,
  [WorkingStepType.Shipping]: TransportIcon,
  [WorkingStepType.Programming]: ProgrammingIcon,
  [WorkingStepType.OrderPicking]: OrderPickingIcon,
  [WorkingStepType.Packaging]: PackagingIcon,
  [WorkingStepType.BeadBlasting]: BlastingIcon,
  [WorkingStepType.SandBlasting]: BlastingIcon,
  [WorkingStepType.SteelGridBlasting]: BlastingIcon,
  [WorkingStepType.WetBlasting]: BlastingIcon,
  [WorkingStepType.VacuumBlasting]: BlastingIcon,
  [WorkingStepType.CentrifugalBlasting]: BlastingIcon,
  [WorkingStepType.SodaBlasting]: BlastingIcon,
  [WorkingStepType.BristleBlasting]: BlastingIcon,
  [WorkingStepType.DryIceBlasting]: BlastingIcon,
  [WorkingStepType.PencilBlasting]: BlastingIcon,
  [WorkingStepType.WeldGrinding]: WeldGrindingIcon,
  [WorkingStepType.DieSinking]: DieSinkingIcon,
  [WorkingStepType.Nitriding]: NitridingIcon,
  [WorkingStepType.RobotStudWelding]: RobotStudWeldingIcon,
  [WorkingStepType.RobotWelding]: RobotWeldingIcon,
  [WorkingStepType.WireErosion]: WireErosionIcon,
  [WorkingStepType.Hemming]: HemmingIcon,
  [WorkingStepType.Cleaning]: CleaningIcon,
  [WorkingStepType.CathodicDipCoating]: DipCoatingIcon,
  [WorkingStepType.ElectrophoreticDipCoating]: DipCoatingIcon,
  [WorkingStepType.FlowDrilling]: FlowDrillingIcon,
  [WorkingStepType.Foiling]: FoilingIcon,
  [WorkingStepType.HoleProtectioning]: HoleProtectioningIcon,
  [WorkingStepType.Operating]: OperatingIcon,
  [WorkingStepType.QualityControlling]: QualityControllingIcon,
  [WorkingStepType.Researching]: ResearchingIcon,
  [WorkingStepType.VibratoryDeburring]: VibratoryDeburringIcon,
  [WorkingStepType.Sawing3d]: SawingIcon,
  [WorkingStepType.NicklePlating]: NicklePlating,
  [WorkingStepType.Loading]: UndefinedIcon,
  [WorkingStepType.Unloading]: UndefinedIcon,
  [WorkingStepType.NotInitialized]: UndefinedIcon,
  [WorkingStepType.Setup]: UndefinedIcon,
  [WorkingStepType.WetPainting]: PowderCoating,
  [WorkingStepType.MicroJointRemoval]: MicroJointRemoval,
  [WorkingStepType.Reaming]: Drilling,
  [WorkingStepType.Riveting]: Riveting,
  [WorkingStepType.OrbitalSanding]: OrbitalSanding,
}

const WorkingStepIconSvg = React.forwardRef(
  (props: WorkingStepIconSvgProps, ref: React.Ref<SVGSVGElement>) => {
    const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()

    const svgTitle = props.title
      ? props.title
      : !props.disableTitle
      ? getLocalizedWorkingStep(props.workingStepType)
      : undefined

    const SvgComponent =
      WorkingStepIconsMap[props.workingStepType] || UndefinedIcon

    if (SvgComponent === UndefinedIcon) {
      return <div style={{ width: props.attributes?.width || '32px' }} />
    }

    if (props.disableTooltip) {
      return (
        <SvgComponent
          {...(props.attributes || {})}
          {...(props.svgProps || {})}
          className={`icon ${props.className || ''}`}
          ref={ref}
        />
      )
    } else {
      return (
        <Tooltip title={svgTitle} placement="left">
          <span>
            <SvgComponent
              {...props.attributes}
              className={`icon ${props.className || ''}`}
              ref={ref}
            />
          </span>
        </Tooltip>
      )
    }
  }
)

WorkingStepIconSvg.displayName = 'WorkingStepIconSvg'

export { WorkingStepIconSvg }
