import { WorkingStepType } from '../services/APIs/InternalAPI/internal-api.contracts'

export const WorkingStepTypeLabel = new Map<WorkingStepType, string>([
  [WorkingStepType.NotInitialized, 'undefined'],
  [WorkingStepType.SheetCutting, 'sheet cutting'],
  [WorkingStepType.Sawing, 'sawing'],
  [WorkingStepType.SheetBending, 'bending'],
  [WorkingStepType.CounterSinking, 'counter sinking'],
  [WorkingStepType.Milling, 'milling'],
  [WorkingStepType.TubeOrProfileCutting, 'tube or profile cutting'],
  [WorkingStepType.Turning, 'turning'],
  [WorkingStepType.Purchasing, 'purchasing'],
  [WorkingStepType.Engraving, 'engraving'],
  [WorkingStepType.ThreadTapping, 'thread tapping'],
  [WorkingStepType.Anodising, 'anodising'],
  [WorkingStepType.Assembling, 'assembling'],
  [WorkingStepType.BoltPressing, 'bolt pressing'],
  [WorkingStepType.Deburring, 'deburring'],
  [WorkingStepType.Drilling, 'drilling'],
  [WorkingStepType.EdgeMilling, 'edge milling'],
  [WorkingStepType.EdgeTrimming, 'edge trimming'],
  [WorkingStepType.LevelTensioning, 'level tensioning'],
  [WorkingStepType.NutPressing, 'nut pressing'],
  [WorkingStepType.PicklingPassivating, 'pickling passivating'],
  [WorkingStepType.Polishing, 'polishing'],
  [WorkingStepType.PowderCoating, 'powder coating'],
  [WorkingStepType.Punching, 'punching'],
  [WorkingStepType.Rolling, 'rolling'],
  [WorkingStepType.StudWelding, 'stud welding'],
  [WorkingStepType.TubeProfileOrBarBending, 'tube profile or bar bending'],
  [WorkingStepType.Welding, 'welding'],
  [WorkingStepType.ZincPlating, 'zinc plating'],
  [WorkingStepType.ZincPlatingRack, 'zinc plating rack'],
  [WorkingStepType.ZincPlatingBarrel, 'zinc plating barrel'],
  [WorkingStepType.Designing, 'designing'],
  [WorkingStepType.Loading, 'loading'],
  [WorkingStepType.Unloading, 'unloading'],
  [WorkingStepType.SheetShearing, 'sheet shearing'],
  [WorkingStepType.Setup, 'setup'],
  [WorkingStepType.Certifying, 'certifying'],
  [WorkingStepType.Shipping, 'shipping'],
  [WorkingStepType.Programming, 'programming'],
  [WorkingStepType.OrderPicking, 'order picking'],
  [WorkingStepType.Packaging, 'packaging'],
  [WorkingStepType.BeadBlasting, 'bead blasting'],
  [WorkingStepType.SandBlasting, 'sand blasting'],
  [WorkingStepType.SteelGridBlasting, 'steel grid blasting'],
  [WorkingStepType.WetBlasting, 'wet blasting'],
  [WorkingStepType.VacuumBlasting, 'vacuum blasting'],
  [WorkingStepType.CentrifugalBlasting, 'centrifugal blasting'],
  [WorkingStepType.BristleBlasting, 'bristle blasting'],
  [WorkingStepType.SodaBlasting, 'soda blasting'],
  [WorkingStepType.DryIceBlasting, 'dry ice blasting'],
  [WorkingStepType.PencilBlasting, 'pencil blasting'],
  [WorkingStepType.WeldGrinding, 'weld grinding'],
  [WorkingStepType.RobotWelding, 'robot welding'],
  [WorkingStepType.RobotStudWelding, 'robot stud welding'],
  [WorkingStepType.Nitriding, 'nitriding'],
  [WorkingStepType.DieSinking, 'die sinking'],
  [WorkingStepType.WireErosion, 'wire erosion'],
  [WorkingStepType.Hemming, 'hemming'],
  [WorkingStepType.VibratoryDeburring, 'vibratory deburring'],
  [WorkingStepType.Foiling, 'foiling'],
  [WorkingStepType.Cleaning, 'cleaning'],
  [WorkingStepType.QualityControlling, 'quality controlling'],
  [WorkingStepType.ElectrolyticPolishing, 'electrolytic polishing'],
  [WorkingStepType.CathodicDipCoating, 'cathodic dip coating'],
  [WorkingStepType.ElectrophoreticDipCoating, 'electrophoretic dip coating'],
  [WorkingStepType.FlowDrilling, 'flow drilling'],
  [WorkingStepType.HoleProtectioning, 'hole protectioning'],
  [WorkingStepType.Operating, 'operating'],
  [WorkingStepType.Researching, 'researching'],
  [WorkingStepType.Sawing3d, 'sawing 3d'],
  [WorkingStepType.NicklePlating, 'nickle plating'],
  [WorkingStepType.WetPainting, 'wet painting'],
  [WorkingStepType.MicroJointRemoval, 'microjoint removal'],
  [WorkingStepType.Reaming, 'reaming'],
  [WorkingStepType.Riveting, 'riveting'],
  [WorkingStepType.OrbitalSanding, 'orbital sanding'],

  // [WorkingStepType.NotInitialized, 'undefined'],
  // [WorkingStepType.SheetCutting, 'sheet cutting'],
  // [WorkingStepType.Sawing, 'sawing'],
  // [WorkingStepType.SheetBending, 'bending'],
  // [WorkingStepType.CounterSinking, 'counter sinking'],
  // [WorkingStepType.Milling, 'milling'],
  // [WorkingStepType.TubeOrProfileCutting, 'tube or profile cutting'],
  // [WorkingStepType.Turning, 'turning'],
  // [WorkingStepType.Purchasing, 'purchasing'],
  // [WorkingStepType.Engraving, 'engraving'],
  // [WorkingStepType.ThreadTapping, 'thread tapping'],
  // [WorkingStepType.Anodising, 'anodising'],
  // [WorkingStepType.Assembling, 'assembling'],
  // [WorkingStepType.BoltPressing, 'bolt pressing'],
  // [WorkingStepType.Deburring, 'deburring'],
  // [WorkingStepType.Drilling, 'drilling'],
  // [WorkingStepType.EdgeMilling, 'edge milling'],
  // [WorkingStepType.EdgeTrimming, 'edge trimming'],
  // [WorkingStepType.LevelTensioning, 'level tensioning'],
  // [WorkingStepType.NutPressing, 'nut pressing'],
  // [WorkingStepType.Packaging, 'packaging'],
  // [WorkingStepType.Polishing, 'polishing'],
  // [WorkingStepType.ElectrolyticPolishing, 'electrolytic polishing'],
  // [WorkingStepType.PowderCoating, 'powder coating'],
  // [WorkingStepType.Punching, 'punching'],
  // [WorkingStepType.Rolling, 'rolling'],
  // [WorkingStepType.StudWelding, 'stud welding'],
  // [WorkingStepType.TubeProfileOrBarBending, 'tube profile or bar bending'],
  // [WorkingStepType.Welding, 'welding'],
  // [WorkingStepType.ZincPlating, 'zinc plating'],
  // [WorkingStepType.Designing, 'designing'],
  // [WorkingStepType.Loading, 'loading'],
  // [WorkingStepType.Unloading, 'unloading'],
  // [WorkingStepType.SheetShearing, 'sheet shearing'],
  // [WorkingStepType.Setup, 'setup'],
  // [WorkingStepType.Certifying, 'certifying'],
  // [WorkingStepType.Shipping, 'shipping'],
  // [WorkingStepType.DieSinking, 'die sinking'],
  // [WorkingStepType.Nitriding, 'nitriding'],
  // [WorkingStepType.RobotStudWelding, 'robot stud welding'],
  // [WorkingStepType.RobotWelding, 'robot welding'],
  // [WorkingStepType.WireErosion, 'wire erosion'],
  // [WorkingStepType.Hemming, 'hemming'],
  // [WorkingStepType.Cleaning, 'cleaning'],
  // [WorkingStepType.CathodicDipCoating, 'cathodic dip coating'],
  // [WorkingStepType.ElectrophoreticDipCoating, 'electrophoretic dip coating'],
  // [WorkingStepType.FlowDrilling, 'flow drilling'],
  // [WorkingStepType.Foiling, 'foiling'],
  // [WorkingStepType.HoleProtectioning, 'hole protectioning'],
  // [WorkingStepType.Operating, 'operating'],
  // [WorkingStepType.QualityControlling, 'quality controlling'],
  // [WorkingStepType.Researching, 'researching'],
  // [WorkingStepType.VibratoryDeburring, 'vibratory deburring'],
  // [WorkingStepType.WeldGrinding, 'weld grinding'],
])
